import React, { useEffect, useState } from "react";
import axios from "axios";

function Read() {
    const [files, setFiles] = useState([]);
    const [data, setData] = useState([]);

    const host = process.env.REACT_APP_API_URL_CLIENT;

    const handleDelete = async (index) => {
      try {
        const deletedPhotoId = data[index].id;
        const updatedData = [...data.slice(0, index), ...data.slice(index + 1)];
    
        await axios.delete(`/api/gallery/delete/${deletedPhotoId}`);
        setData(updatedData);
      } catch (err) {
        console.error(err);
      }
    }
    

    const handleUpload = async (e) => {
      try {
          const formData = new FormData();
  
          // Append each file to the formData
          for (const file of files) {
              formData.append('files', file);
          }
  
          const res = await axios.post(`/pages/upload-multiple`, formData);
          
          console.log(res.data); // Log the response data
  
          // Update data with new images
          setData(prevData => [...prevData, ...res.data.filenames]); 
  
          setFiles([]); // Clear uploaded files
          e.preventDefault();
      } catch (err) {
          console.error(err);
      }
  } 

      useEffect(() => {
        axios.get(`/api/gallery`)
          .then(res => {
            setData(res.data);
          })
          .catch(err => console.log(err));
      }, [files])

      return (
        <div>
          <div className="row d-flex pt-5">
            <input type="file" className="col-5" multiple onChange={e => setFiles(e.target.files)} />
            <button className="btn col-3 btn-dark" onClick={e => handleUpload(e)}>Upload new photos</button>
          </div>
          <div className="mt-4 row">
          {/* {deleteIndex !== null && (
            <div className="modal">
              <div className="modal-content">
                <p>Are you sure you want to delete this image?</p>
                <button onClick={handleConfirmDelete}>Yes</button>
                <button onClick={() => setDeleteIndex(null)}>No</button>
              </div>
            </div>
          )} */}
            {data.map((item, index) => (
              <div className="col-xl-3 col-lg-5 col-md-6 mb-3" key={index}>
                <div className="row">
                  <img
                    src={`/images/${item.photo}`}
                    alt={item.photo}
                    className=" pb-3"
                  />
                </div>
                <div className="col-3">
                  <button className="btn btn-danger" onClick={() => handleDelete(index)}>
                    Delete
                  </button>
                </div>
                {/* <input type="number" className="col-3" defaultValue={item.sort} min={1} /> */}
              </div>
            ))}
          </div>
        </div>
      );
    }

export default Read;