import React, { useEffect, useState } from "react";
import axios from "axios";
 
function Read() {
    const [file, setFile] = useState();
    const [header, setHeader] = useState('');
    const [description, setDescription] = useState('');
    const [mainPhoto, setMainPhoto] = useState('');

    const host = process.env.REACT_APP_API_URL_CLIENT;

    useEffect(() => {
        axios.get(`/api/pages`)
        .then(res => {
            const aboutUsData = res.data.find(item => item.type === 'about-us').value;
                setHeader(aboutUsData.header);
                setDescription(aboutUsData.description);
                setMainPhoto(aboutUsData.mainPhoto);
        })
        .catch(err => console.log(err));
    }, [])

    const handleUpdate = async (e) => {
        e.preventDefault();

        if (header.length > 0 && description.length > 0) {
            const updatedData = {
                header,
                description,
                mainPhoto,
            };
    
           await axios.put(`/api/pages/update/about-us`, updatedData)
                .then(res => {
                    alert('Page "About Us" successfully updated')
                })
                .catch(err => console.log(0))
        }
    };

    const handleUpload = (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('file', file);

        axios.post(`/pages/upload`, formData)
            .then(res => {
                const handleUpdatePhoto = async () => {
                    const prevMainPhoto = mainPhoto;
                    setMainPhoto(res.data.filename);

                    const updatedData = {
                        header,
                        description,
                        mainPhoto: res.data.filename,
                    };
                
                    if (prevMainPhoto) {
                        axios.delete(`/api/pages/delete-photo/about-us`)
                            .then(res => console.log('Previous photo deleted.'))
                            .catch(err => console.log(err));
                    }
                
                    await axios.put(`/api/pages/update/about-us`, updatedData)
                        .then(res => {
                            console.log('photo updated', mainPhoto);
                        })
                        .catch(err => console.log(err));
                }

                handleUpdatePhoto();
            })
            .catch(err => console.log(err));
    }

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    }

    return (
        <div>
            <form onSubmit={handleUpdate}>
                <div className="mb-3 mt-5 row">
                    <label htmlFor="inputHeader" className="col-form-label col-2 fw-bold">Header</label>
                    <div className="col-10">
                        <input type="text" className="form-control" required id="inputHeader" defaultValue={header} onChange={(e) => setHeader(e.target.value)}/>
                    </div>
                </div>
                <div className="mb-3 mt-5 row">
                    <label htmlFor="inputDescription" className="col-form-label fw-bold col-2">Description</label>
                    <div className="col-10">
                        <textarea type="text" className="form-control" required id="inputDescription" defaultValue={description} onChange={(e) => setDescription(e.target.value)}/> 
                    </div>
                </div>
                <input type="hidden" name="_csrf" value="<%= csrfToken %>"></input>
                <div className="mb-3 mt-5 row">
                    <label htmlFor="inputMainPhoto" className="col-form-label fw-bold col-2">Main Photo</label>
                    <img 
                        src={`/images/${mainPhoto}`} 
                        alt="about-us-main-photo" 
                        className="col-4" 
                        />
                        
                    <div className="col-6">
                        <input type="file" className="" onChange={handleFileChange} />
                        <button className="btn btn-dark" onClick={handleUpload}>Upload new photo</button>
                    </div>                                
                </div>
                <button className="btn btn-success" type="submit">Update page</button>
            </form>
        </div>          
    );
}

export default Read;