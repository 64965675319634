import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, Routes, Route, useNavigate } from "react-router-dom";
import '../../App.css';
import AboutUsRead from '../admin/pages/about-us/Read';
import OurProgramsRead from '../admin/pages/our-programs/Read';
import ContactUsRead from '../admin/pages/contact-us/Read';
import GalleryRead from '../admin/pages/gallery/Read';
import ProtectedRoute from '../admin/providers/ProtectedRoute';

const AdminHome = (props) => {
    const { type } = props;
    const [data, setData] = useState([]);

    const host = process.env.REACT_APP_API_URL_CLIENT;
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem('isAuthenticated');
        navigate('/admin/login');
    }
   
    useEffect(() => {
        const fetchData = async() => {
            try {
                const res = await axios.get(`/api/pages`);
                const response = res.data.find(item => item.type === 'about-us');

                
            } catch (err) {
                console.log(err);
            }
        }

        fetchData();
    }, [])

    return (
        <div className="App">
            <header className="row bg-white admin-header mx-0">
                <div className="py-4 d-flex justify-content-between">
                    <div className="d-flex">
                        <div>
                            <h3 className="mx-4">Admin Panel</h3>
                        </div>
                        <div>
                            <a href="/" className="text-decoration-none text-dark">
                                <div className="fw-bold fs-5">Shawcliffe Dayhome</div>
                            </a>
                        </div>
                    </div>
                    
                    <div style={{ marginRight: '3%'}}>
                        <button className="btn btn-outline-dark" onClick={handleLogout}>Logout</button>
                    </div>
                </div>
            </header>
            <div className="d-flex">
                <aside className="fixed-aside col-xl-2 col-lg-3 col-md-3 col-sm-4">
                    <div className="row ">
                        <Link to={"/admin/about-us"} className="mb-3 mt-4 text-decoration-none text-dark">About Us</Link>
                        <Link to={"/admin/our-programs"} className="mb-3 text-decoration-none text-dark">Our Programs</Link>
                        <Link to={"/admin/gallery"} className="mb-3 text-decoration-none text-dark">Gallery</Link>
                        <Link to={"/admin/contact-us"}className="text-decoration-none text-dark">Contact Us</Link>
                    </div>
                </aside>
                <div className="col-xl-10 col-lg-9 col-md-9 col-sm-8 main-block">
                    { type === 'home' && (
                        <div className="welcome-section">
                            <div>
                                <h3>Welcome to</h3>
                                <h2>Admin Panel Shawcliffe Dayhome</h2>
                            </div>
                        </div>
                    )}
                    
                    { type === 'about-us' && (
                        <AboutUsRead />
                    )}

                    { type === 'our-programs' && (
                        <OurProgramsRead />
                    )}


                    { type === 'contact-us' && (
                        <ContactUsRead />
                    )}

                    { type === 'gallery' && (
                        <GalleryRead />
                    )}
                </div>
            </div>
            <footer className="py-3 bg-dark text-white py-5 mb-0 d-flex justify-content-center">
                <div>
                    <div className="fw-bold">
                        © 2023 Luna Dayhome
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default AdminHome;