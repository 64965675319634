import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import '../../App.css';
import { AuthContext } from "./providers/AuthProvider";
import { cookiesRequest } from "../../utils/httpRequests";

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext);
    const navigate = useNavigate();

    const host = process.env.REACT_APP_API_URL_CLIENT;

    useEffect(() => {
        axios.get(`/`)
            .then(res => console.log(res))
            .catch(err => console.log(err));

    });

    const handleLogin = async (e) => {
        e.preventDefault();

        if (username.length > 0 && password.length > 0) {
            const user = {
                username,
                password
            };
    
            await axios.post(`/api/users/login`, user)
                .then(res => {        
                    alert('Login successful');
                    localStorage.setItem('isAuthenticated', 'true');
                    setIsAuthenticated(true);
                    console.log(isAuthenticated);
                    navigate('/admin/');
                })
                .catch(err => {
                    console.log(err)
                    alert('Invalid username or password')
                });
        }
    }

    return (
        <div className="login-body">
            <div>
                <div className="text-center mb-3">
                    <h3>Welcome to</h3>
                    <h2>Shawcliffe Dayhome Admin Panel</h2>
                </div>
                <form className="login-form" onSubmit={handleLogin}>
                    <div className="form-group mb-3">
                        <label htmlFor="exampleInputEmail1">Username</label>
                        <input 
                            type="username" 
                            className="form-control" 
                            aria-describedby="userHelp" 
                            placeholder="Username"
                            required
                            onChange={(e) => setUsername(e.target.value)}
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label htmlFor="inputPassword">Password</label>
                        <input 
                            type="password" 
                            className="form-control" 
                            id="inputPassword" 
                            placeholder="Password"
                            required
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <button type="submit" className="btn btn-primary">Submit</button>
                </form>
            </div>
        </div>
    )
}

export default Login;