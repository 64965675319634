import React, { useEffect, useState } from "react";
import axios from "axios";
// import fs from 'fs';
 
function Read() {
    const [file, setFile] = useState();
    const [header, setHeader] = useState('');
    const [openHours, setOpenHours] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [location, setLocation] = useState('');
    const [mainPhoto, setMainPhoto] = useState('');

    const host = process.env.REACT_APP_API_URL_CLIENT;

    useEffect(() => {
        axios.get(`/api/pages`)
        .then(res => {
            const contactUsData = res.data.find(item => item.type === 'contact-us').value;
                setHeader(contactUsData.header);
                setOpenHours(contactUsData.openHours);
                setEmail(contactUsData.email);
                setPhone(contactUsData.phone);
                setLocation(contactUsData.location);
                setMainPhoto(contactUsData.mainPhoto);
        })
        .catch(err => console.log(err));
    }, [])

    const handleUpdate = async (e) => {
        e.preventDefault();

        if (header.length > 0) {
            const updatedData = {
                header,
                openHours,
                email,
                phone,
                location,
                mainPhoto,
            };
    
           await axios.put(`/api/pages/update/contact-us`, updatedData)
                .then(res => {
                    alert('Page "Contact Us" successfully updated')
                })
                .catch(err => console.log(0))
        }
    };

    const handleUpload = (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('file', file);

        axios.post(`/pages/upload`, formData)
            .then(res => {
                const handleUpdatePhoto = async () => {
                    const prevMainPhoto = mainPhoto;
                    setMainPhoto(res.data.filename);

                    const updatedData = {
                        header,
                        openHours,
                        email,
                        phone,
                        location,
                        mainPhoto: res.data.filename,
                    };
                    console.log(updatedData);
                
                    if (prevMainPhoto) {
                        axios.delete(`/api/pages/delete-photo/contact-us`)
                            .then(res => console.log('Previous photo deleted.'))
                            .catch(err => console.log(err));
                    }
                
                    await axios.put(`/api/pages/update/contact-us`, updatedData)
                        .then(res => {
                            console.log('photo updated', mainPhoto);
                        })
                        .catch(err => console.log(err));
                }

                handleUpdatePhoto();
            })
            .catch(err => console.log(err));
    }

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    }

    return (
        <div>
            <form onSubmit={handleUpdate}>
                <div className="mb-3 mt-5 row">
                    <label htmlFor="inputHeader" className="col-form-label col-2 fw-bold">Header</label>
                    <div className="col-10">
                        <input type="text" className="form-control" required id="inputHeader" defaultValue={header} onChange={(e) => setHeader(e.target.value)}/>
                    </div>
                </div>
                <div className="mb-3 mt-5 row">
                    <label htmlFor="inputOpenHours" className="col-form-label fw-bold col-2">Open Hours</label>
                    <div className="col-10">
                        <input type="text" className="form-control" required id="inputOpenHours" defaultValue={openHours} onChange={(e) => setOpenHours(e.target.value)}/> 
                    </div>
                </div>
                <div className="mb-3 mt-5 row">
                    <label htmlFor="inputEmail" className="col-form-label fw-bold col-2">Email</label>
                    <div className="col-10">
                        <textarea type="text" className="form-control" required id="inputEmail" defaultValue={email} onChange={(e) => setEmail(e.target.value)}/> 
                    </div>
                </div>
                <div className="mb-3 mt-5 row">
                    <label htmlFor="inputPhone" className="col-form-label fw-bold col-2">Phone</label>
                    <div className="col-10">
                        <textarea type="text" className="form-control" required id="inputPhone" defaultValue={phone} onChange={(e) => setPhone(e.target.value)}/> 
                    </div>
                </div>
                <div className="mb-3 mt-5 row">
                    <label htmlFor="inputLocation" className="col-form-label fw-bold col-2">Location</label>
                    <div className="col-10">
                        <textarea type="text" className="form-control" required id="inputLocation" defaultValue={location} onChange={(e) => setLocation(e.target.value)}/> 
                    </div>
                </div>
                <input type="hidden" name="_csrf" value="<%= csrfToken %>"></input>
                <div className="mb-3 mt-5 row">
                    <label htmlFor="inputMainPhoto" className="col-form-label fw-bold col-2">Main Photo</label>
                    <img 
                        src={`/images/${mainPhoto}`}
                        alt="about-us-main-photo" 
                        className="col-4" 
                        />
                        
                    <div className="col-6">
                        <input type="file" className="" onChange={handleFileChange} />
                        <button className="btn btn-dark" onClick={handleUpload}>Upload new photo</button>
                    </div>                                
                </div>
                <button className="btn btn-success" type="submit">Update page</button>
            </form>
        </div>          
    );
}

export default Read;