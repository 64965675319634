import React, { useEffect, useState } from "react";
import axios from "axios";

function Read() {
    const [file, setFile] = useState();
    const [header, setHeader] = useState('');
    const [description, setDescription] = useState('');
    const [list, setList] = useState([]);
    const [mainPhoto, setMainPhoto] = useState('');
    const [newListItem, setNewListItem] = useState('');
    const [showNewInput, setShowNewInput] = useState(false);

    const host = process.env.REACT_APP_API_URL_CLIENT;

    useEffect(() => {
        axios.get(`/api/pages`)
        .then(res => {
            const aboutUsData = res.data.find(item => item.type === 'our-programs').value;
                setHeader(aboutUsData.header);
                setDescription(aboutUsData.description);
                setMainPhoto(aboutUsData.mainPhoto);
                setList(aboutUsData.list);
                const test = async () => {
                    setList(aboutUsData.list);
                }

                test();
        })
        .catch(err => console.log(err));
    }, [])

    const handleShowNewInput = () => {
        setShowNewInput(true);
    }

    const handleAddItem = (e) => {
        e.preventDefault();

        if (newListItem) {
            if (list && list.length > 0) {
                console.log('list exist');
                const test = async () => {
                    setList(prevList => [...prevList, newListItem]);
                }

                test();
                // await setList(prevList => [...prevList, newListItem]);
            } else {
                console.log('set list newListItem')
                const test = async () => {
                    setList(newListItem);
                }

                test();
                // setList(newListItem);
            }

            console.log(list);

            setNewListItem('');
            setShowNewInput(false);
        }
    }

    const handleNewListItemChange = (e) => {
        setNewListItem(e.target.value);
        console.log(e.target.value);
    }

    const handleNewListItemBlur = () => {
        if (newListItem.length > 0) {
            setTimeout(() => {
                handleAddItem({ preventDefault: () => {} });
            }, 500); // Delay auto-save by 500 milliseconds (adjust as needed)
        } 
    };

    const handleRemoveItem = (index) => {
        const updatedList = [...list];
        updatedList.splice(index, 1);
        setList(updatedList);
    }

    const handleUpdate = async (e) => {
        e.preventDefault();

        if (header.length > 0 && description.length >0) {
            const updatedData = {
                header,
                description,
                mainPhoto,
                list
            };
    
           await axios.put(`/api/pages/update/our-programs`, updatedData)
                .then(res => {
                    alert('Page "Our Programs" successfully updated')
                })
                .catch(err => console.log(0))
        }
    };


    const handleUpload = (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('file', file);
        formData.append('type', 'our-programs');

        axios.post(`/pages/upload`, formData)
            .then(res => {
                const handleUpdatePhoto = async (e) => {
                    const prevMainPhoto = mainPhoto;
                    setMainPhoto(res.data.filename);

                    const updatedData = {
                        header,
                        description,
                        mainPhoto: res.data.filename,
                    };

                    if (prevMainPhoto) {
                        axios.delete(`/api/pages/delete-photo/our-programs`)
                            .then(res => console.log('Previous photo deleted.'))
                            .catch(err => console.log(err));
                    }
                
                    await axios.put(`/api/pages/update/our-programs`, updatedData)
                        .then(res => console.log('photo updated', mainPhoto))
                        .catch(err => console.log(0))
                }

                handleUpdatePhoto();
            })
            .catch(err => console.log(err));
    }

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    }

    return (
        <div>
            <form onSubmit={handleUpdate}>
                <div className="mb-3 mt-5 row">
                    <label htmlFor="inputHeader" className="col-form-label col-2 fw-bold">Header</label>
                    <div className="col-10">
                        <input type="text" className="form-control" id="inputHeader" defaultValue={header} onChange={(e) => setHeader(e.target.value)}/>
                    </div>
                </div>
                <div className="mb-3 mt-5 row">
                    <label htmlFor="inputDescription" className="col-form-label col-2 fw-bold">Description</label>
                    <div className="col-10">
                        <textarea type="text" className="form-control" id="inputDescription" defaultValue={description} onChange={(e) => setDescription(e.target.value)}/> 
                    </div>
                </div>
                <div className="mb-3 mt-5 row">
                    <label htmlFor="inputList" className="col-form-label col-2  fw-bold">List of programs</label>
                        <div className="row d-flex justify-content-start col-10">
                        {Array.isArray(list) && list.map((item, index) => (
                                <div key={index} className="d-flex align-items-center">
                                    <input 
                                        type="text" 
                                        name="" 
                                        className="form-control my-2 w-75" 
                                        id="inputHeader" 
                                        defaultValue={item} 
                                        onChange={(e) => {
                                            const updatedList = [...list];
                                            updatedList[index] = e.target.value;
                                            setList(updatedList)
                                        }}
                                    />
                                    <button 
                                        className="btn btn-outline-danger ms-2" 
                                        onClick={() => handleRemoveItem(index)}
                                    >
                                        Remove
                                    </button>
                                </div>
                            ))}
                            {/* {showNewInput && ( */}
                                <div>
                                    <input
                                        type="text"
                                        className="form-control my-2 w-75 col-10"
                                        value={newListItem}
                                        onChange={(e) => handleNewListItemChange(e)} 
                                        onBlur={handleNewListItemBlur} // Auto-save on blur
                                    />
                                </div>
                            {/* )} */}
                            {!showNewInput && (
                                <div>
                                    <button className="btn btn-warning" onClick={handleShowNewInput}>Add new program</button>
                                </div>
                            )}
                        </div>
                    </div>
                <div className="mb-3 mt-5 row">
                    <label htmlFor="inputMainPhoto" className="col-form-label col-2 fw-bold">Main Photo</label>
                    <img 
                        src={`/images/${mainPhoto}`}
                        alt="our-programs-main-photo" 
                        className="col-4" 
                        />
                        
                    <div className="col-6">
                        <input type="file" className="" onChange={handleFileChange} />
                        <button className="btn btn-dark" onClick={handleUpload}>Upload new photo</button>
                    </div>                                
                </div>
                <button className="btn btn-success" type="submit">Update page</button>
            </form>
        </div>
           
    );
}

export default Read;