import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from 'react-router-dom';
import '../src/App.css';

function Home() {
    const [aboutUs, setAboutUs] = useState(null);
    const [ourProgram, setOurProgram] = useState(null);
    const [contactUs, setContactUs] = useState(null);
    const [galleryInfo, setGalleryInfo] = useState(null);
    const [galleryImages, setGalleryImages] = useState([]);
    const [currentSlide, setCurrentSlide] = useState(0);

    const host = process.env.REACT_APP_API_URL_CLIENT;

    console.log(host);
    const currentPath = window.location.origin;
    console.log(currentPath, 'current path');

    useEffect(() => {
        axios.get(`/api/pages`)
        .then(res => {
            const aboutUsData = res.data.find(item => item.type === 'about-us');
            setAboutUs(aboutUsData.value);

            const ourProgramData = res.data.find(item => item.type === 'our-programs');
            setOurProgram(ourProgramData.value);

            console.log(ourProgramData.value);

            const contactUsData = res.data.find(item => item.type === 'contact-us');
            setContactUs(contactUsData.value);

            const galleryData = res.data.find(item => item.type === 'gallery');
            setGalleryInfo(galleryData.value);
        })
        .catch(err => console.log(err));

        axios.get(`/api/gallery`)
            .then(res => {
                setGalleryImages(res.data);
            })
            .catch(err => console.log(err));

        showSlides();
    }, [])

    const showSlides = () => {
        const items = document.getElementsByClassName("gallery-images-item");
        for (let i = 0; i < items.length; i++) {
            items[i].classList.remove('active');
        }
        for (let i = currentSlide; i < currentSlide + 3; i++) {
            if (items[i]) items[i].classList.add('active');
        }
    }

    const nextSlide = () => {
        if (currentSlide < galleryImages.length - 3) {
            setCurrentSlide(currentSlide + 1);
        }
    }

    const prevSlide = () => {
        if (currentSlide > 0) {
            setCurrentSlide(currentSlide - 1);
        }
    }

    return (
        <div className="App">
            <header className="row bg-white">
                <div className="d-flex justify-content-around py-4" id="header-section">
                    <div className="header-name">
                        <a href="/" className="text-decoration-none fw-bold text-dark">
                            Shawcliffe Dayhome
                        </a>
                    </div>
                    <div className="d-flex" id="header-items">
                        <a href="#about-us-section" className="text-decoration-none px-2 fw-bold text-dark">About Us</a>
                        <a href="#our-programs-section" className="text-decoration-none px-2 fw-bold text-dark">Our Programs</a>
                        <a href="#gallery-section" className="text-decoration-none px-2 fw-bold text-dark">Gallery</a>
                        <a href="#contact-us-section" className="text-decoration-none px-2 fw-bold text-dark">Contact Us</a>
                    </div>
                </div>
            </header>
            <div id="home-section" style={{ backgroundColor: 'rgb(88 125 255 / 49%)', }}>                
                { aboutUs && (
                    <div 
                        className="py-5 mb-5" 
                        id="about-us-section"
                        >
                        <div className="" id="about-us-text">
                            <div className="row" id="about-us-header">
                                <h1 className="fw-bolder text-start display-1" style={{ wordWrap: 'break-word',}}>
                                    {aboutUs.header}
                                </h1>
                            </div>
                            <div className="row" id="about-us-description">
                                <h6 className="fw-bold text-start fs-4" style={{ wordWrap: 'break-word'}}>
                                    {aboutUs.description}
                                </h6>
                            </div>
                        </div>
                        {/* {currentPath} */}
                        <div className="" id="about-us-photo">
                            <img 
                                src={`/images/${aboutUs.mainPhoto}`} 
                                alt="about-us-main-photo" 
                                className="img-fluid" 
                                style={{ width: '100%', height: 'auto', }}/>
                        </div>
                    </div>
                )}
                { ourProgram && (
                    <div 
                        className="" 
                        id="our-programs-section"
                    >
                        <div className="row" id="our-programs-photo">
                            <img 
                                src={`/images/${ourProgram.mainPhoto}`} 
                                alt="our-programs-main-photo" 
                                className="img-fluid row" 
                                style={{ width: '100%', height: 'auto', }}/>
                        </div>
                        <div className="row" id="our-programs-text">
                            <div className="our-programs-text-content">
                                <div className="fw-bolder" id="our-programs-header" style={{ wordWrap: 'break-word'}}>
                                    {ourProgram.header}
                                </div>
                            </div>
                            <div className="our-programs-text-content">
                                <div className="fw-bold fs-3" id="our-programs-description" style={{ wordWrap: 'break-word'}}>
                                    {ourProgram.description}
                                </div>
                            </div>
                            { ourProgram.list && (
                                <div className="fw-bold fs-3 row our-programs-text-content" >
                                    <ul className="" id="our-programs-list">
                                        {ourProgram.list.map((item, index) => (
                                            <li className="text-start" key={index}>{item}</li>
                                        ))}
                                    </ul>

                                </div>
                            )}
                        </div>
                    </div>
                )}
                {galleryInfo && (
                    <div id="gallery-section" className="d-inline-block">
                    <h2 className="fw-bolder display-3" id="gallery-header">{galleryInfo.header}</h2>
                        <div className="gallery-images d-flex">
                            <div className="">
                                { galleryImages.slice(currentSlide, currentSlide + 3).map((item, index) => (
                                    <img
                                        src={`/images/${item.photo}`}
                                        alt={item.photo}
                                        className="gallery-images-item"
                                        key={index}
                                    />
                                ))}
                            </div>
                        </div>
                        <div className="mt-4 d-flex justify-content-center" id="gallery-btns">
                            <div className="mr-5" style={{marginRight: '1rem'}}>
                                <button className="btn border-dark" onClick={prevSlide} disabled={currentSlide === 0}>Previous</button>
                            </div>
                            <div>
                                <button className="btn border-dark px-4" onClick={nextSlide} disabled={currentSlide === galleryImages.length - 3}>Next</button>
                            </div>
                        </div>
                    </div>
                )}
                { contactUs && (
                    <div 
                        className="d-flex justify-content-around" 
                        id="contact-us-section"
                    >
                        <div className="p-4 col" id="contact-us-info">
                            <h1 className="fw-bolder text-start" style={{ wordWrap: 'break-word', maxWidth: '500px' }}>
                                {contactUs.header}
                            </h1>
                            <div>
                                <div className="fw-bolder text-start fs-md-4 py-4 contact-us-info-item">
                                    Open: {contactUs.openHours}
                                </div>
                                <div className="fw-bolder text-start  pb-4 contact-us-info-item">
                                    Email: {contactUs.email}
                                </div>
                                <div className="fw-bolder text-start pb-4 contact-us-info-item">
                                    Phone: {contactUs.phone}
                                </div>
                                <div className="fw-bolder text-start contact-us-info-item" style={{ wordWrap: 'break-word', maxWidth: '25rem' }}>
                                    Location: 
                                    <div id="location-info">
                                        {contactUs.location}
                                    </div>
                                </div>
                            </div>                             
                        </div>
                        <div className="col" id="contact-us-photo">
                            <img 
                                src={`/images/${contactUs.mainPhoto}`} 
                                alt="contact-us-main-photo" 
                                className="img-fluid" 
                                style={{ width: '100%', height: '100%', }}/>
                        </div>
                    </div>
                )}
            </div>
            <footer className="py-3 bg-dark text-white py-5 mb-0">
                <div className="justify-content-around d-flex" id="footer-section">
                    <div className="fw-bold footer-item">
                        © 2023 Luna Dayhome
                    </div>
                    <div className="">
                        <a href="#about-us-section" className="text-decoration-none px-2 fw-bold text-white footer-item text-sm">About Us</a>
                        <a href="#our-programs-section" className="text-decoration-none px-2 fw-bold text-white footer-item">Our Programs</a>
                        <a href="#gallery-section" className="text-decoration-none px-2 fw-bold text-white footer-item">Gallery</a>
                        <a href="#contact-us-section" className="text-decoration-none px-2 fw-bold text-white footer-item">Contact Us</a>
                        <Link to="/admin/about-us" className="text-decoration-none px-2 fw-bold text-white footer-item">Admin Panel</Link>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default Home;