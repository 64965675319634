// import logo from './logo.svg';
// Import Custom CSS 
import './App.css';
import * as React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom"; 
import Home from './Home';
import "bootstrap/dist/css/bootstrap.min.css"; 
import AdminHome from './components/admin/AdminHome';
import Login from './components/admin/Login';
import ProtectedRoute from './components/admin/providers/ProtectedRoute';
import AuthProvider from './components/admin/providers/AuthProvider';

function App() {
  // const { isAuthenticated } = useAuth();

  return (  
    <AuthProvider>
      <BrowserRouter>
        <Routes>
            <Route path='/' element={ <Home />} />
            <Route path='/admin/login' element={ <Login />} />

            <Route path='/admin' element={<ProtectedRoute element={<AdminHome type="home" />} />} />
            <Route path='/admin/about-us' element={<ProtectedRoute element={ <AdminHome type="about-us" />} />} />
            <Route path='/admin/our-programs' element={<ProtectedRoute element={ <AdminHome type="our-programs" />}  />} />
            <Route path='/admin/contact-us' element={<ProtectedRoute element={ <AdminHome type="contact-us" />}  />} />
            <Route path='/admin/gallery' element={<ProtectedRoute element={ <AdminHome type="gallery" />}  />} />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  )
};

  export default App;